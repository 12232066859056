import React from "react"
import { graphql } from "gatsby"
import EventGroups, { ALL } from "../../components/EventGroups/eventGroups"
import Footer from "../../components/Footer/footer"
import Info from "../../components/Info/info"
import "./league-template.scss"
import BaseLayout from "../../components/Layout/layout"

const LeagueTemplate = ({ data, pageContext }) => {
  const league = data.league
  const eventEdges = data.events ? data.events.edges : []
  const leagueLogo = league.logo
    ? league.logo.file.url
    : `/logos/competition/${league.logoUri}`
  return (
    <BaseLayout>
      <div className="league-header-container">
        <div className="league-header">
          <img
            src={`${leagueLogo}`}
            onError={e =>
              e.target.setAttribute("src", "/logos/competition/fallback.png")
            }
            className="league-logo"
            alt={league.name}
          />
          <h1 className="league-name">{league.name}</h1>
        </div>
      </div>
      <Info
        header={`Hitta rätt ${league.name}-match`}
        description={`Här kan du se de kommande matcherna i ${league.name} som sänds i TV eller
        via en stramingtjänst på nätet. Klicka på loggorna för mer information om respektive sändning
        och hur du får tag på matchen. Du kan även klicka på matcherna för att få information om
        matchodds, eventuella speltips och förhandsanalyser.`}
      />
      <div className="league-content-container">
        <EventGroups
          key={`league-events-${league.name}`}
          eventEdges={eventEdges}
          numberOfDays={ALL}
        />
      </div>
      <Footer links={pageContext.allLinks} />
    </BaseLayout>
  )
}

export default LeagueTemplate

export const pageQuery = graphql`
  query leagueQuery($id: String!) {
    league: contentfulLeague(contentful_id: { eq: $id }) {
      name
      logoUri
      logoUriNeg
      logo {
        file {
          url
        }
      }
    }
    events: allContentfulEvent(
      filter: { leagueId: { eq: $id }, node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            name
            uri
          }
          sport {
            code
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          detail {
            detail
          }
          channelLinks {
            channel
            externalUrl
          }
          channels {
            id
            name
            externalUrl
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
